.invoice-table{
  margin-bottom: 50px;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  .invoice-table-header{
    border-bottom: 1px solid #ccc;
    font-weight: bold;
    padding-bottom: 10px;
    float: left;
    width: 100%;
  }

  .invoice-table-products{
  }
  .data-row{
    .mutable{
      text-align: right;
    }
    
  }

  .mutable{

    .delete_line{
      margin-right: 10px;
    }
  }

  .invoice-table-line{
    padding: 5px 0;
    border-bottom: 1px solid #eee;

    .input{
      border-radius: 3px;
      cursor: pointer;
      transition: all 200ms ease-in-out;
      padding: 8px 4px;
      &:hover{
        background: $gray;
      }
    }

    .mutable{

      transition: opacity 200ms ease-in-out;
      text-align: right;

    }

    .amount{
      text-align: right;
      input{
        text-align: right;
      }
    }

    .input_edit{
      display: none;

      .form-control{
        width: 100%;
        padding: 3px;
        background: $gray;
        border-radius: 3px;
        font-size: 12px;
      }
    }
  }

}

.invoice-table-totals{
  float: right;
  font-size: 1.2em;
  margin: 30px 0;

  td {
    text-align: right;
    padding: 5px 0;
    padding-left: 10px;
  }

  .invoice-table-totals-total-row{
    border-top: 1px solid $navy;
    font-weight: bold;
  }
}