/* Only demo */
@media (max-width: 1000px) {
  .welcome-message {
    display: none;
  }
}

.form-group.required .control-label:after {
  content:" *";
  color:red;
}

.note .note_form{
  display: none;
}

.well.pinned{
  background: #fff9d5;
}

.navbar-top-links .dropdown-menu li a {
  padding: 3px !important;
}